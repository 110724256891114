import { uuid } from 'vue-uuid';
import initFields from '@/entities/blog/Tag.fields';

export default class Tag {
    id: string;
    name: string;
    articleIds: string[];
    fields: { [key: string]: unknown };
    constructor({ id = uuid.v4(), name = '', articleIds = [] } = {}) {
        this.id = id;
        this.name = name;
        this.articleIds = articleIds;
        this.fields = initFields(this);
    }
    get data() {
        return {
            id: this.id,
            name: this.name,
            articleIds: this.articleIds,
        };
    }
}
